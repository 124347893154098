.inputContainer {
    margin: 0 0 1.5rem;
}

.twoFieldsRow .inputContainer {
    width: 45%;
}

.inputContainer input::placeholder {
    color: #757575;
    font-size: 0.8125rem;
    font-family: "ITC Avant Garde Std text";
    line-height: 1.3125rem;
    overflow: hidden;
    white-space: nowrap;
}

.twoFieldsRow {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.inputSpan {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 3.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    background-color: #fff;
    border-radius: 0.3125rem;
    position: relative;
    width: 100%;
}

.inputSpan input {
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
}

.errorText {
    margin-top: 0.1875rem;
    font-family: "ITC Avant Garde Std text";
    text-align: right;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    min-height: 0.8125rem;
    color: #d70000;
}

.registerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100%;
    padding-bottom: 0 !important;
}

.register {
    width: 100%;
    margin: auto;
    flex-shrink: 0;
    flex-grow: 1;
    justify-content: center;
    padding: 1.25rem 3.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.title {
    margin-bottom: 0.625rem;
    text-align: center;
    width: 100%;
}

.description {
    text-align: center;
    width: 100%;
}

.registerFormContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 25px;
}

.registerForm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    margin: 0.5rem auto;
}

.inputSpan input:focus {
    outline: none;
}

.buttons {
    text-align: center;
}

.goBack {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 0 3rem;
    cursor: pointer;
}

.goBack svg {
    vertical-align: middle;
}
