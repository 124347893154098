/*=============================================
=            section title            =
=============================================*/

.section-title {
  font-size: 48px;
  font-weight: 400;

  color: $black--two;

  @include respond(large-mobile) {
    font-size: 35px;
  }

  &--secondary {
    font-size: 14px;
    font-weight: 500;

    color: $black--two;

    &--style2 {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.6;

      color: $grey--seven;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    letter-spacing: 1px;

    color: $black--two;
  }

  &--style-two {
    font-family: $spectral;
    font-weight: 500;
  }

  &--style-three {
    font-family: $spectral;
    font-size: 50px;
    font-weight: 400;
    line-height: 1.3;

    color: $blue--five;

    @include respond(large-mobile) {
      font-size: 40px;
    }
  }

  &--style-four {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 20px;

    position: relative;

    text-align: center;
    letter-spacing: 2px;

    color: $blue--five;

    span {
      z-index: 9;

      display: inline-block;

      padding: 0 20px;

      background-color: $white;
    }

    &:before {
      position: absolute;
      z-index: -1;
      top: 50%;
      left: 0;

      width: 100%;
      height: 1px;

      content: "";
      transform: translateY(-50%);

      background-color: #ECEAEA;
    }
  }

  &--concepthome {
    h1 {
      @include respond(small-desktop) {
        font-size: 40px;
      }

      @include respond(extra-small-mobile) {
        font-size: 40px;
      }
    }
  }
}

.widget-slider-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;

  position: relative;

  margin-bottom: 35px;
  padding-right: 60px;

  color: $black--two;
}

.rotate-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;

  position: absolute;
  top: 250px;

  letter-spacing: 1px;

  color: $black--two;

  &--left {
    transform: rotate(90deg) translate(30%, 180%);
  }

  &--right {
    right: -15px;

    transform: rotate(-90deg);
  }
}

/*----------  instagram title  ----------*/

.instagram-title-container {
  .title {
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    line-height: 1.3;

    margin-bottom: 30px;

    letter-spacing: 2px;

    color: rgba(51, 51, 51, 0.35);
  }

  .subtitle {
    font-family: $spectral;
    font-size: 50px;
    font-weight: 300;
    font-style: italic;
    line-height: 1.3;

    color: #333;

    @include respond(large-mobile) {
      font-size: 40px;
    }

    &--blue {
      font-weight: 400;
      font-style: normal;

      color: #404969;
    }
  }

  &--style-two {
    .subtitle {
      font-style: normal;

      color: $blue--five;
    }
  }
}

.section-label {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;

  margin-bottom: 20px;

  letter-spacing: 1px;

  color: #333333;
}

.section-title-container {
  h1 {
    font-size: 48px;
    font-weight: 400;
    line-height: 1.5;

    margin-bottom: 20px;

    @include respond(small-desktop) {
      font-size: 40px;
    }

    @include respond(extra-small-mobile) {
      font-size: 40px;
    }
  }

  p {
    line-height: 1.5;
  }
}


/*=====  End of section title  ======*/

