.searchContainer {
    display: inline-flex;
    width: 100%;
    align-items: center;
}

.searchContainer svg {
    vertical-align: middle;
    fill: black !important;
}

.bdGreenTheme {
    background: white !important;
    color: #1ba9aa !important;
    border: solid 2px #1ba9aa !important;
}

.searchIcon {
    min-height: 3.5rem;
    display: flex;
    align-items: center;
}

.productCat {
    text-align: left;
    text-transform: capitalize;
    font-style: italic;
    font-size: 13px;
    color: #707070;
    white-space: break-spaces;

}

.selectCell {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    min-height: 3.4375rem;
    align-items: center;
    width: 100%;
    background: white;
    border-radius: 0.5rem;
}

.image {
    display: block;
    position: relative;
    width: 60px;
    height: 60px;
    background-color: #eeedf0;
}

.image img {
    position: absolute;
    bottom: 0;
    display: block;
    max-height: 90%;
    margin: auto;
    max-width: 90%;
    top: 0;
    left: 0;
    right: 0;
}

.productDesignation {
    color: black;
    font-size: 14px;
    font-weight: 300;
    padding-bottom: 10px;
    margin-bottom: 0;
    text-align: left;
    letter-spacing: 0.03rem;
    white-space: break-spaces;

}

.ref {
    hyphens: auto;
    margin: 5px 0;
    word-wrap: break-word;
    text-align: left;
    letter-spacing: 0.02rem;
    font-weight: 600;
    color: black;
    white-space: break-spaces;
}

.hoverRef:hover .ref,
.hoverRef:hover .productDesignation {
    color: #1BA9AA;
    cursor: pointer;
}

.listWrapper,
.productWrapper {
    padding: 20px;
}

.cartTableRow {
    padding: 10px 0;
    text-align: center;
}

.resultsBox {
    overflow-y: auto;
    padding-left: 4rem;
    padding-top: 3.5rem;
}

.resultsContainer {
    position: absolute;
    top: 90%;
    width: 100%;
    left: 0;
    z-index: 99;
    background: white;
    height: 90vh;
    border: solid 1px lightgray;
}

.closeSearch {
    cursor: pointer;
    position: absolute;
    left: 97%;
    top: 2%;
}

.resultsContainer::before {
    content: "";
    position: absolute;
    top: -0.5rem;
    right: 8%;
    background-color: #fff;
    display: flex;
    width: 0.8125rem;
    height: 0.8125rem;
    transform: rotate(-135deg);
    border-right: 0.0625rem solid #e5e5e5;
    border-bottom: 0.0625rem solid #e5e5e5;
}

.searchInput {
    border: none;
    font-size: inherit;
    outline: none;
    font-family: inherit;
    min-height: 3.4375rem;
    width: 100%;
    border-radius: 0.5rem;
}

@media (max-width: 768px) {
    .searchInput,
    .searchContainer,
    .resultsContainer {
        display: none !important;
    }


}