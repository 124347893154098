.address {
    border: solid 1px #f6f6f6;
    padding: 25px;
    border-radius: 0.3125rem;
    background-color: #f6f6f6;
}

.inputContainer {
    margin: 0 0 1.5rem;
}

.defaultAddress {
    color: #757575;
    font-size: 0.8125rem;
    font-family: "ITC Avant Garde Std text";
    line-height: 1.3125rem;
    overflow: hidden;
    white-space: nowrap;
}

.addressSelected {
    padding: 15px;
}

.inputSpan {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    font-family: "ITC Avant Garde Std text";
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 3.0625rem;
    padding: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0.0625rem solid #cecece;
    border-radius: 0.3125rem;
    position: relative;
    width: 100%;
    background-color: #f6f6f6;
}

.inputSpan select {
    font-family: "ITC Avant Garde Std text";
    border: 0;
    border-radius: 0.3125rem;
    font-size: 0.8125rem;
    line-height: 1.3125rem;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: transparent;
    outline: none;
    font-weight: bold;
}

.description {
    margin-bottom: 2rem;
    color: orange;
}

.description svg {
    vertical-align: middle;
}

.addAddress {
    text-align: center;
}
