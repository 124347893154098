.selected,
.selected svg {
    color: #1ba9aa;
    display: flex;
    text-decoration: none;
}

h4 {
    margin-bottom: 25px;
}

.link {
    display: flex;
    color: inherit;
    text-decoration: none;
}

.link:hover,
.logout:hover,
.link:hover svg {
    color: #1ba9aa;
}

.logout {
    cursor: pointer;
}
