/*=============================================
=            product thumb            =
=============================================*/


/* product grid */

.product-grid,
.product-list {

  &__image {
    position: relative;

    display: block;
    text-align: center;
    background: #f0f0f0;
    width: 100%;


    height: 317px;

    img {
      width: auto;
      max-width: 100%;

      transition: 0.9s;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      padding: 15px;

      &:nth-child(1) {
        z-index: 2;

        visibility: visible;

        opacity: 1;
      }

      &:nth-child(2) {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        visibility: hidden;

        opacity: 0;
      }


    }
  }

  &__floating-badges {
    position: absolute;
    z-index: 9;
    top: 20px;
    left: 20px;

    display: flex;
    flex-direction: column;

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 48px;

      z-index: 3;

      display: inline-block;

      width: 48px;
      height: 48px;
      margin-bottom: 10px;

      text-align: center;
      text-transform: lowercase;

      color: $white;
      border-radius: 100%;

      @include respond(small-desktop) {
        line-height: 40px;

        width: 40px;
        height: 40px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.onsale {
        background-color: $green--one;
      }

      &.hot {
        background-color: $red--three;
      }

      &.out-of-stock {
        background-color: $black--two;
      }
    }
  }

  &__floating-icons {
    position: absolute;
    z-index: 9;
    top: 20px;
    right: 20px;

    display: flex;
    flex-direction: column;


    button {
      line-height: 40px;

      display: inline-block;
      visibility: hidden;

      width: 40px;
      height: 40px;
      margin-bottom: 5px;
      padding: 0;

      text-align: center;

      opacity: 0;
      color: $grey;
      border: none;
      background: none;
      background-color: $white;

      &:nth-child(1) {
        margin-top: 5px;

        transition: 0.3s;
      }

      &:nth-child(2) {
        margin-top: 5px;

        transition: 0.6s;
      }

      &:nth-child(3) {
        margin-top: 5px;

        transition: 0.9s;
      }

      &:hover,
      &.active {
        svg {
          color: $black--two;
        }
      }

      svg {
        font-size: 20px;
      }
    }
  }

  &__content {
    position: relative;

    padding-top: 25px;

    .title {
      position: relative;

      h3 {


        font-size: 17px;
        font-weight: 400;
        line-height: 1.6;

        display: block;
        visibility: visible;

        margin-bottom: 10px;

        transition-duration: 0.6s;
        transform: translateY(0);

        opacity: 1;
        color: $black--two;

      }

      & > button,
      & > a {
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3;

        position: absolute;
        top: 0;
        left: 0;

        display: inline-block;
        visibility: hidden;

        transition-duration: 0.6s;
        transform: translateY(60%);

        opacity: 0;
        color: $theme-biotech-green;
        border: none;
        background: none;

        @include respond(small-desktop) {
          font-size: 16px;
        }

        &:before {
          display: inline-block;

          margin-right: 5px;

          content: "+";
        }
      }
    }

    .price {
      .discounted-price {
        font-size: 14px;
        font-weight: 600;
        line-height: 1;

        color: $black--two;
      }

      .main-price {
        font-size: 14px;
        font-weight: 600;
        line-height: 1;

        margin-right: 5px;

        color: $black--two;

        &.discounted {
          font-size: 12px;
          font-weight: 600;
          line-height: 1;

          text-decoration: line-through;

          color: $grey--twelve;
        }
      }
    }
  }

  &:hover {
    .product-grid__image,
    .product-list__image {
      max-height: 300px;

      text-align: center;
      background: #f0f0f0;

      img {
        max-height: 317px;
        padding: 15px;
        width: auto;

        &:nth-child(2) {
          visibility: visible;

          opacity: 1;
        }
      }
    }

    .product-grid__content {
      .title {
        h3 {
          a {
            visibility: hidden;

            transform: translateY(-60%);

            opacity: 0;
          }
        }

        & > button,
        & > a {
          visibility: visible;

          transform: translateY(0);

          opacity: 1;
        }
      }
    }

    .product-grid__floating-icons,
    .product-list__floating-icons {
      button {
        visibility: visible;

        opacity: 1;

        &.active {
          border-color: $theme-biotech-green;
          background-color: $theme-biotech-green;

          svg {
            color: $white;
          }
        }

        &:nth-child(1) {
          margin-top: 0;
        }

        &:nth-child(2) {
          margin-top: 0;
        }

        &:nth-child(3) {
          margin-top: 0;
        }
      }
    }
  }

  &--absolute-content {
    position: relative;

    .product-grid__content {
      position: absolute;
      z-index: 3;
      bottom: 30px;
      left: 15px;
    }
  }
}


/* product list */

.product-list {
  display: flex;

  @include respond(large-mobile) {
    flex-wrap: wrap;
  }

  &__image {
    flex-basis: 370px;

    margin-right: 30px;

    @include respond(large-mobile) {
      flex-basis: 100%;

      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__content {
    flex-basis: calc(100% - 400px);

    padding-top: 0;

    @include respond(large-mobile) {
      flex-basis: 100%;
    }

    .title {
      h3 {
        a {
          font-size: 25px;
        }
      }
    }

    .price {
      margin-bottom: 40px;

      .main-price {
        font-size: 16px;

        &.discounted {
          font-size: 14px;
        }
      }

      .discounted-price {
        font-size: 16px;
      }
    }

    .short-description {
      line-height: 1.5;

      margin-bottom: 30px;

      .cat {
        a {
          font-style: italic;
          font-size: smaller;
          text-transform: capitalize;
        }
      }
    }
  }
}


/*----------  widget product  ----------*/

.widget-product-wrapper {
  .single-widget-product-wrapper {
    margin-bottom: 20px;
    padding-bottom: 20px;

    border-bottom: 1px solid $grey--ten;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;

      border-bottom: 0;
    }
  }
}

.single-widget-product {
  display: flex;

  &__image {
    width: 120px;

    a {
      display: block;

      img {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: calc(100% - 120px);
    padding-left: 20px;


    &__top {
      h3 {
        line-height: 1;

        a {
          font-size: 16px;
          line-height: 22px;

          color: $grey;

          &:hover {
            color: $black--two;
          }
        }
      }

      .price {
        .discounted-price {
          font-size: 14px;
          font-weight: 600;
          line-height: 1;

          display: inline-block;

          color: $black--two;
        }

        .main-price {
          font-size: 14px;
          font-weight: 600;
          line-height: 1;

          display: inline-block;

          margin-right: 5px;

          color: $black--two;

          &.discounted {
            font-size: 12px;
            font-weight: 600;
            line-height: 1;

            text-decoration: line-through;

            color: $grey--twelve;
          }
        }
      }

      .rating {
        .yellow {
          color: $yellow--two;
        }
      }
    }

    &__bottom {
      button,
      a {
        &.cart-btn {
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;

          top: 0;
          left: 0;

          display: inline-block;

          transition-duration: 0.6s;

          color: $red--one;
          border: none;
          background: none;

          &.active {
            &:before {
              font-size: 15px;
              font-weight: 400;

              display: inline-block;

              margin-right: 5px;

              vertical-align: middle;
            }
          }

          &:before {
            display: inline-block;

            margin-right: 5px;

            content: "+";
          }
        }
      }
    }
  }
}


/* product thumb resize */

.shop-products {
  .col-lg-3 {
    transition: 0.5s;
  }

  .grid {
    .product-list {
      display: none;
    }

    &.three-column {
      .col-lg-3 {
        flex: 1 0 33.33%;

        max-width: 33.33%;

        transition: 0.5s;
      }
    }
  }

  .list {
    .product-grid {
      display: none;
    }

    & > .col-lg-3 {
      flex: 1 0 100%;

      max-width: 100%;

      transition: 0.5s;
    }
  }
}


/*=====  End of product thumb  ======*/

