/*=============================================
=            hero slider            =
=============================================*/

.hero-slider-one {
  overflow-x: hidden;

  &__wrapper {
    .swiper-container {
      overflow: visible;

      .ht-swiper-button-nav {
        visibility: hidden;

        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: none;

        @include respond(extra-large-mobile) {
          visibility: visible;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          opacity: 1;
        }

        &:after {
          @include respond(extra-small-mobile) {
            font-size: 30px;
          }
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: -120px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: -120px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }
  }

  &__slide {
    position: relative;

    .slider-image {
      img {
        width: 100%;
      }
    }

    .slider-content {
      left: 70px;

      @include respond(extra-small-mobile) {
        left: 30px;
      }

      @include absLeft;

      .color-title {
        font-size: 16px;
        font-weight: 600;

        letter-spacing: 3px;
        text-transform: uppercase;

        @include respond(extra-small-mobile) {
          font-size: 10px;

          margin-bottom: 5px;
        }

        &--blue {
          color: #68AAEB;
        }
      }

      .main-title {
        font-size: 40px;
        font-weight: 600;
        line-height: 1.4;

        color: $black--two;

        @include respond(large-mobile) {
          font-size: 30px;
        }

        @include respond(small-mobile) {
          font-size: 20px;
        }

        @include respond(extra-small-mobile) {
          font-size: 20px;

          margin-bottom: 10px;
        }
      }

      .lezada-button {
        @include respond(extra-small-mobile) {
          font-size: 10px;

          padding: 10px 15px;
        }
      }
    }
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }
}

.hero-slider-two {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 770px !important;

    @include respond(large-desktop) {
      height: 550px !important;
    }

    @include respond(small-desktop) {
      height: 450px !important;
    }

    @include respond(extra-large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      height: 800px !important;
    }

    @include respond(large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      height: 750px !important;
    }

    @include respond(extra-small-mobile) {
      height: 730px !important;
    }
  }

  &__image {
    position: absolute;
    z-index: 9;
    right: 15%;
    bottom: 0;

    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      right: 20%;

      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    position: absolute;
    z-index: 99;
    bottom: 25%;
    left: 8%;

    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 56px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);
    }
  }

  &__pagination {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    right: 4%;
    bottom: 15%;

    display: flex;
    align-items: center;

    @include respond(small-desktop) {
      font-size: 44px;

      & .border {
        width: 50px;
      }
    }

    @include respond(extra-large-mobile) {
      display: none;
    }

    @include respond(large-mobile) {
      display: none;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .current {
      transform: translateX(40px);

      color: $black--two;
    }

    & .border {
      display: block;

      width: 80px;
      height: 2px;
      margin: 5px 15px 0;

      transform: translateX(40px);

      border: none !important;
      background-color: $black--two;
    }

    & .total {
      transform: translateX(40px);

      color: rgba($black, 0.5);
    }
  }

  .swiper-slide-active {
    .hero-slider-two {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }

      &__pagination {
        & > * {
          opacity: 1;
        }

        & .current {
          transition-delay: 1s;
          transform: translateX(0);
        }

        & .border {
          transition-delay: 1.2s;
          transform: translateX(0);
        }

        & .total {
          transition-delay: 1.4s;
          transform: translateX(0);
        }
      }
    }
  }
}

.hero-slider-three {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 690px !important;

    @include respond(large-desktop) {
      height: 550px !important;
    }

    @include respond(small-desktop) {
      height: 450px !important;
    }

    @include respond(extra-large-mobile) {
      height: 650px !important;
    }

    @include respond(large-mobile) {
      height: 600px !important;
    }

    @include respond(extra-small-mobile) {
      height: 550px !important;
    }
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      display: block;

      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 56px;
      font-weight: 300;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);

      a {
        padding-bottom: 5px;

        text-transform: uppercase;

        color: $black--two;
        border-bottom: 1px solid $black--two;

        &:hover {
          color: $red--one;
          border-color: $red--one;
        }
      }
    }
  }

  .swiper-slide-active {
    .hero-slider-three {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }
    }
  }

  .swiper-pagination {
    bottom: 30px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    &-bullet {
      width: 10px;
      height: 10px;

      &-active {
        width: 15px;
        height: 15px;
        margin: 0;

        opacity: 1;
        border: 1px solid $black--two;
        border-radius: 100%;
        background-color: transparent;
      }
    }
  }
}

.hero-slider-four {
  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 870px !important;

    @include respond(large-desktop) {
      height: 620px !important;
    }

    @include respond(small-desktop) {
      height: 465px !important;
    }

    @include respond(extra-large-mobile) {
      height: 650px !important;
    }

    @include respond(large-mobile) {
      height: 600px !important;
    }

    @include respond(extra-small-mobile) {
      height: 550px !important;
    }
  }

  &__content {
    position: absolute;
    z-index: 99;
    bottom: 10%;
    left: 8%;


    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 56px;
      font-weight: 300;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);

      a {
        padding-bottom: 5px;

        text-transform: uppercase;

        color: $black--two;
        border-bottom: 1px solid $black--two;

        &:hover {
          color: $red--one;
          border-color: $red--one;
        }
      }
    }
  }

  .swiper-slide-active {
    .hero-slider-four {
      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }
    }
  }

  .swiper-pagination {
    right: 100px !important;
    bottom: 100px !important;
    left: auto !important;

    display: flex;
    align-items: center;
    justify-content: center;

    width: auto !important;

    @include respond(small-desktop) {
      right: 50px;
      bottom: 50px;
    }

    @include respond(large-mobile) {
      right: 50px;
      bottom: 50px;
    }

    &-bullet {
      width: 10px;
      height: 10px;

      &-active {
        width: 15px;
        height: 15px;
        margin: 0;

        opacity: 1;
        border: 1px solid $black--two;
        border-radius: 100%;
        background-color: transparent;
      }
    }
  }
}

.hero-slider-five {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $black--two;
        border: none;
        background: none;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 25px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 690px !important;

    @include respond(large-desktop) {
      height: 550px !important;
    }

    @include respond(small-desktop) {
      height: 450px !important;
    }

    @include respond(extra-large-mobile) {
      height: 650px !important;
    }

    @include respond(large-mobile) {
      height: 500px !important;
    }

    @include respond(extra-small-mobile) {
      height: 450px !important;
    }
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      display: block;

      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 22px;
      font-weight: 400;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 52px;
      font-weight: 300;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);
    }
  }

  .swiper-slide-active {
    .hero-slider-five {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }
    }
  }

  .swiper-pagination {
    bottom: 30px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    &-bullet {
      width: 10px;
      height: 10px;

      &-active {
        width: 15px;
        height: 15px;
        margin: 0;

        opacity: 1;
        border: 1px solid $black--two;
        border-radius: 100%;
        background-color: transparent;
      }
    }
  }
}

.hero-slider-six {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 850px !important;

    @include respond(large-desktop) {
      height: 750px !important;
    }

    @include respond(small-desktop) {
      height: 650px !important;
    }

    @include respond(extra-large-mobile) {
      height: 650px !important;
    }

    @include respond(large-mobile) {
      height: 600px !important;
    }
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      max-width: 170px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      display: block;

      max-width: 150px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 99px;
      font-weight: 600;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 66px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);
    }
  }

  .swiper-slide-active {
    .hero-slider-six {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }
    }
  }

  .swiper-pagination {
    bottom: 30px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    &-bullet {
      width: 10px;
      height: 10px;

      &-active {
        width: 15px;
        height: 15px;
        margin: 0;

        opacity: 1;
        border: 1px solid $black--two;
        border-radius: 100%;
        background-color: transparent;
      }
    }
  }
}

.hero-slider-seven {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 80vh !important;

    .content {
      height: 87%;
    }
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      max-width: 170px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      display: block;

      max-width: 150px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $white;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 58px;
      font-weight: 300;
      line-height: 1.3;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $white;

      @include respond(small-desktop) {
        font-size: 43px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);

      a {
        color: $black--two;
        border-color: $white;
        background-color: $white;

        &:hover {
          color: $white !important;
          border-color: $white;
          background-color: transparent;
        }
      }
    }
  }

  &__pagination {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    right: 4%;
    bottom: 15%;

    display: flex;
    align-items: center;

    @include respond(small-desktop) {
      font-size: 44px;

      & .border {
        width: 50px;
      }
    }

    @include respond(extra-large-mobile) {
      display: none;
    }

    @include respond(large-mobile) {
      display: none;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .current {
      transform: translateX(40px);

      color: $white;
    }

    & .border {
      display: block;

      width: 80px;
      height: 2px;
      margin: 5px 15px 0;

      transform: translateX(40px);

      border: none !important;
      background-color: $white;
    }

    & .total {
      transform: translateX(40px);

      color: rgba($white, 0.5);
    }
  }

  .swiper-slide-active {
    .hero-slider-seven {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }

      &__pagination {
        & > * {
          opacity: 1;
        }

        & .current {
          transition-delay: 1s;
          transform: translateX(0);
        }

        & .border {
          transition-delay: 1.2s;
          transform: translateX(0);
        }

        & .total {
          transition-delay: 1.4s;
          transform: translateX(0);
        }
      }
    }
  }
}

.hero-slider-eight {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 900px !important;

    @include respond(large-desktop) {
      height: 550px !important;
    }

    @include respond(small-desktop) {
      height: 450px !important;
    }

    @include respond(extra-large-mobile) {
      height: 650px !important;
    }

    @include respond(large-mobile) {
      height: 600px !important;
    }

    @include respond(extra-small-mobile) {
      height: 550px !important;
    }
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      display: block;

      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-family: $spectral;
      font-size: 16px;
      font-weight: 300;
      font-style: italic;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $white;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-family: $spectral;
      font-size: 56px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $white;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);
    }
  }

  .swiper-slide-active {
    .hero-slider-eight {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }
    }
  }

  .swiper-pagination {
    bottom: 30px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    &-bullet {
      width: 10px;
      height: 10px;

      &-active {
        width: 15px;
        height: 15px;
        margin: 0;

        opacity: 1;
        border: 1px solid $black--two;
        border-radius: 100%;
        background-color: transparent;
      }
    }
  }
}

.hero-slider-nine {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 100vh !important;

    background: rgb(248, 248, 248);
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      max-width: 270px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      display: block;

      max-width: 250px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    img {
      animation: bounceUpDown 2s linear infinite alternate both;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 15px;
      font-weight: 400;
      line-height: 1.25;

      margin-bottom: 30px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-family: "Allura", cursive;
      font-size: 73px;
      font-weight: 400;
      line-height: 1;

      margin-bottom: 30px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 53px;
      }

      @include respond(extra-large-mobile) {
        font-size: 46px;
      }

      @include respond(large-mobile) {
        font-size: 46px;
      }

      @include respond(small-mobile) {
        font-size: 46px;
      }

      @include respond(extra-small-mobile) {
        font-size: 40px;
      }
    }

    & .slider-link {
      transform: translateY(30px);

      a {
        padding-bottom: 5px;

        text-transform: uppercase;

        color: $black--two;
        border-bottom: 1px solid $black--two;

        &:hover {
          color: $red--one;
          border-color: $red--one;
        }
      }
    }
  }

  &__pagination {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    right: 4%;
    bottom: 15%;

    display: flex;
    align-items: center;

    @include respond(small-desktop) {
      font-size: 44px;

      & .border {
        width: 50px;
      }
    }

    @include respond(extra-large-mobile) {
      display: none;
    }

    @include respond(large-mobile) {
      display: none;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .current {
      transform: translateX(40px);

      color: $white;
    }

    & .border {
      display: block;

      width: 80px;
      height: 2px;
      margin: 5px 15px 0;

      transform: translateX(40px);

      border: none !important;
      background-color: $white;
    }

    & .total {
      transform: translateX(40px);

      color: rgba($white, 0.5);
    }
  }

  .swiper-slide-active {
    .hero-slider-nine {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }

      &__pagination {
        & > * {
          opacity: 1;
        }

        & .current {
          transition-delay: 1s;
          transform: translateX(0);
        }

        & .border {
          transition-delay: 1.2s;
          transform: translateX(0);
        }

        & .total {
          transition-delay: 1.4s;
          transform: translateX(0);
        }
      }
    }
  }
}

.hero-slider-ten {
  &__wrapper {
    .swiper-pagination {
      position: absolute !important;
      bottom: 50px !important;
      left: 30px !important;

      display: inline-block !important;

      width: auto !important;
    }

    .swiper-pagination-bullet {
      width: 20px;
      height: 5px;
      margin-right: 5px;

      opacity: 0.2;
      border-radius: 0;
      background-color: #000;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background-color: $black;
    }
  }

  &__slide {
    height: 840px !important;

    @include respond(large-desktop) {
      height: 800px !important;
    }

    @include respond(small-desktop) {
      height: 750px !important;
    }

    @include respond(large-mobile) {
      height: 750px !important;
    }

    @include respond(extra-small-mobile) {
      height: 600px !important;
    }
  }

  &__content {
    padding: 0 30px;

    text-align: center;

    .image {
      margin-bottom: 30px;
    }

    .title {
      margin-bottom: 15px;

      color: $white;
    }

    .price {
      color: $white;
    }
  }
}

.hero-slider-eleven {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 100vh !important;

    @include respond(large-desktop) {
      height: 100vh !important;
    }

    @include respond(small-desktop) {
      height: 100vh !important;
    }

    @include respond(extra-large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      height: 800px !important;
    }

    @include respond(large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      height: 750px !important;
    }

    @include respond(extra-small-mobile) {
      height: 730px !important;
    }
  }

  &__image {
    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 450px;
    }

    @include respond(small-desktop) {
      right: 20%;

      max-width: 400px;
    }

    @include respond(extra-large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 250px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    @include respond(large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 250px;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    @include respond(extra-large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    @include respond(large-mobile) {
      position: static;

      width: 100%;

      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;

      margin-bottom: 40px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 56px;
      font-weight: 600;
      line-height: 1.3;

      margin-bottom: 40px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 36px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);
    }
  }

  &__pagination {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    bottom: 15%;
    left: 4%;

    display: flex;
    align-items: center;

    @include respond(small-desktop) {
      font-size: 44px;

      & .border {
        width: 50px;
      }
    }

    @include respond(extra-large-mobile) {
      display: none;
    }

    @include respond(large-mobile) {
      display: none;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .current {
      transform: translateX(40px);

      color: $black--two;
    }

    & .border {
      display: block;

      width: 80px;
      height: 2px;
      margin: 5px 15px 0;

      transform: translateX(40px);

      border: none !important;
      background-color: $black--two;
    }

    & .total {
      transform: translateX(40px);

      color: rgba($black, 0.5);
    }
  }

  .swiper-slide-active {
    .hero-slider-eleven {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }

      &__pagination {
        & > * {
          opacity: 1;
        }

        & .current {
          transition-delay: 1s;
          transform: translateX(0);
        }

        & .border {
          transition-delay: 1.2s;
          transform: translateX(0);
        }

        & .total {
          transition-delay: 1.4s;
          transform: translateX(0);
        }
      }
    }
  }
}

.hero-slider-twelve {
  &__wrapper {
    .swiper-container {
      .ht-swiper-button-nav {
        visibility: hidden;

        width: 60px;
        height: 60px;
        padding: 0;

        transition: $transition--default;

        opacity: 0;
        color: $grey--three;
        border: none;
        background: $white;

        @include respond(small-desktop) {
          width: 40px;
          height: 40px;
        }

        @include respond(extra-large-mobile) {
          visibility: visible;

          width: 40px;
          height: 40px;

          opacity: 1;
        }

        @include respond(large-mobile) {
          visibility: visible;

          width: 30px;
          height: 30px;

          opacity: 1;
        }

        &:after {
          font-size: 15px;
        }

        &:hover {
          color: $black--two;
        }

        &:focus {
          outline: none;
        }

        &.swiper-button-next {
          right: 20px;

          @include respond(large-desktop) {
            right: 20px;
          }

          @include respond(small-desktop) {
            right: 20px;
          }

          @include respond(extra-large-mobile) {
            right: 20px;
          }

          @include respond(large-mobile) {
            right: 10px;
          }
        }

        &.swiper-button-prev {
          left: 20px;

          @include respond(large-desktop) {
            left: 20px;
          }

          @include respond(small-desktop) {
            left: 20px;
          }

          @include respond(extra-large-mobile) {
            left: 20px;
          }

          @include respond(large-mobile) {
            left: 10px;
          }
        }
      }
    }

    &:hover {
      .ht-swiper-button-nav {
        visibility: visible;

        opacity: 1;
      }
    }
  }

  &__slide {
    position: relative;

    overflow: hidden;

    width: 100%;

    @include respond(large-desktop) {
    }

    @include respond(small-desktop) {
    }

    // @include respond (extra-large-mobile) {
    // 			display: flex;
    // 			align-items: center;
    // 			flex-direction: column;
    // 			justify-content: center;
    // 		}
    @include respond(large-mobile) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    @include respond(extra-small-mobile) {
    }
  }

  &__image {
    overflow: hidden;

    max-width: 64%;

    transition: all 1s ease 0s;
    transform: scale(0.8);

    opacity: 0;

    @include respond(large-desktop) {
      max-width: 64%;
    }

    @include respond(small-desktop) {
      max-width: 64%;
    }

    @include respond(extra-large-mobile) {
      max-width: 64%;
    }

    @include respond(large-mobile) {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      display: block;

      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }

  &__content {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 64%;

    transform: translate(-50%, -50%);
    white-space: nowrap;

    @include respond(large-mobile) {
      position: static;

      transform: none;
      text-align: center;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .sub-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;

      margin-bottom: 40px;

      transform: translateY(30px);
      letter-spacing: 5px;

      color: $black--two;

      @include respond(small-desktop) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 16px;

        margin-bottom: 20px;
      }

      @include respond(extra-small-mobile) {
        font-size: 14px;
      }
    }

    & .title {
      font-size: 90px;
      font-weight: 600;
      line-height: 1.3;

      margin-bottom: 40px;

      transform: translateY(30px);

      color: $black--two;

      @include respond(large-desktop) {
        font-size: 70px;
      }

      @include respond(small-desktop) {
        font-size: 60px;
      }

      @include respond(extra-large-mobile) {
        font-size: 36px;

        margin-bottom: 20px;
      }

      @include respond(large-mobile) {
        font-size: 36px;
      }

      @include respond(small-mobile) {
        font-size: 36px;
      }

      @include respond(extra-small-mobile) {
        font-size: 30px;
      }
    }

    & .slider-link {
      transform: translateY(30px);

      a {
        font-size: 24px;
        font-weight: 500;

        color: $red--one;

        &:hover {
          color: $black--two;
        }
      }
    }
  }

  &__pagination {
    font-size: 56px;
    font-weight: 400;
    line-height: 1;

    position: absolute;
    bottom: 10%;
    left: 4%;

    display: flex;
    align-items: center;

    @include respond(small-desktop) {
      font-size: 44px;

      & .border {
        width: 50px;
      }
    }

    @include respond(extra-large-mobile) {
      display: none;
    }

    @include respond(large-mobile) {
      display: none;
    }

    & > * {
      transition: all 1s ease 0s;

      opacity: 0;
    }

    & .current {
      transform: translateX(40px);

      color: $black--two;
    }

    & .border {
      display: block;

      width: 80px;
      height: 2px;
      margin: 5px 15px 0;

      transform: translateX(40px);

      border: none !important;
      background-color: $black--two;
    }

    & .total {
      transform: translateX(40px);

      color: rgba($black, 0.5);
    }
  }

  .swiper-slide-active {
    .hero-slider-twelve {
      &__image {
        transition-delay: 0.3s;
        transform: scale(1);

        opacity: 1;
      }

      &__content {
        & > * {
          opacity: 1;
        }

        & .sub-title {
          transition-delay: 1s;
          transform: translateY(0);
        }

        & .title {
          transition-delay: 1.3s;
          transform: translateY(0);
        }

        & .slider-link {
          transition-delay: 1.6s;
          transform: translateY(0);
        }
      }

      &__pagination {
        & > * {
          opacity: 1;
        }

        & .current {
          transition-delay: 1s;
          transform: translateX(0);
        }

        & .border {
          transition-delay: 1.2s;
          transform: translateX(0);
        }

        & .total {
          transition-delay: 1.4s;
          transform: translateX(0);
        }
      }
    }
  }
}

/*=====  End of hero slider  ======*/

