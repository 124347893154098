.title {
    margin-bottom: 3rem;
    margin-top: 3rem;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
}

.goBack {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0 0 3rem;
    cursor: pointer;
}

.goBack svg {
    vertical-align: middle;
}
